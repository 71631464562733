import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetCustomerInvestorGoalBalanceQuery from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';

// Domain
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type Products = {
  product: string;
  title: string;
  unassigned_amount: number;
  img: string;
  alt: string;
  max_width: number;
  class: string;
}

type AssociatedProducts = {
  name: string;
  associated_product_id: string;
}

export default class DistributeUnassignedAmountCardViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.distribute-unassigned-amount-card';

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  is_loading = false;

  associated_product_ids: Array<AssociatedProducts> = [];

  window_number = 0;

  max_number_windows = 1;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  products: Array<Products> = [
    {
      product: 'sowos_pocket',
      title: this.translate('sowos_pocket'),
      unassigned_amount: 0,
      img: 'ahorro_kuspit.svg',
      alt: this.translate('alts.pocket_image'),
      max_width: 25,
      class: 'secondary--text',
    },
    {
      product: 'sowos_wealth',
      title: this.translate('sowos_wealth'),
      unassigned_amount: 0,
      img: 'inversion_allianz.svg',
      alt: this.translate('alts.wealth_image'),
      max_width: 30,
      class: 'accent--text',
    },
  ];

  initialize = async () => {
    this.is_loading = true;
    await this.loadInvestmentProducts();
    await this.checkIfUserHasAtLeastOneLinkedGoal();
    this.is_loading = false;
  }

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      Object.entries(investment_products).forEach((item: any) => {
        this.associated_product_ids.push({
          name: item[1].name,
          associated_product_id: item[1].id,
        });
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  checkIfUserHasAtLeastOneLinkedGoal = async () => {
    // eslint-disable-next-line max-len
    this.search_by_customer_dto.associated_product_id = this.associated_product_ids[0].associated_product_id;
    await this.loadActiveGoals(this.associated_product_ids[0].name);
    // eslint-disable-next-line max-len
    this.search_by_customer_dto.associated_product_id = this.associated_product_ids[1].associated_product_id;
    await this.loadActiveGoals(this.associated_product_ids[1].name);
  }

  loadActiveGoals = async (product_name: string) => {
    try {
      // eslint-disable-next-line max-len
      const active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      if (active_goals.length) {
        // eslint-disable-next-line max-len
        await this.loadCustomerInvestorGoalBalance(this.search_by_customer_dto.associated_product_id, product_name);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_active_goals'));
    }
  }

  loadCustomerInvestorGoalBalance = async (associated_product_id: string, product: string) => {
    try {
      const { unassigned_balance } = await this.get_customer_investor_goal_balance_query
        .execute({ investment_product_id: associated_product_id });
      const item_product = this.products.find((item) => item.product === product);
      if (item_product) {
        item_product.unassigned_amount = unassigned_balance;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      }
    }
  }

  redirectToSpecificDashboard = (index: number) => {
    if (this.products[index].product === 'sowos_pocket') {
      window.location.href = '/sowos-pocket';
    } else {
      window.location.href = '/sowos-wealth';
    }
  }
}
